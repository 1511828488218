// import 'babel-polyfill';     // uncomment this if you want to use for...of, async/await
// or other things that require babel runtime


import './vendor/jquery-global';
import './modules/isMobile';
import './modules/ga';
import './modules/hamburger';
import './modules/animation';
import './modules/results';
import './events/resize';

import postMessage from './modules/iframe-handler';


window.addEventListener('load', () => {
    postMessage();
});