export default function postMessage() {
    let root = document.getElementById('root');
    //console.log('Height', root.clientHeight);
    window.parent.postMessage({
      'pageHeight': root.clientHeight
    }, "*");
  }
  function handleIframeEmbed() {
    if (top !== self) {
      //$('.header, .footer').remove();
      $('body, html').css('overflow-y', 'hidden')
      //$('#root').css('min-height', '1px')
      //$('.tabs').addClass('margin-top');
    }
  }

  function receiveMessage(event) {
    
    //console.log('recived message event', event.data.scroll);
    if (event.data.scroll > $('.busstop').offset().top - $('.busstop').outerHeight() && event.data.scroll < $('.busstop').offset().top +  $('.busstop').outerHeight()) { 
      $('.busstop__animation').addClass('busstop__animation--dawid');
    } else {
      $('.busstop__animation').removeClass('busstop__animation--dawid');
    }
    
}

window.addEventListener("message", receiveMessage, false);

  handleIframeEmbed();