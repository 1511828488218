$.get('/results.php', function (data) {
        var grey = document.querySelectorAll('.results li');
        var download = document.querySelectorAll('.results li a');

        data
            .filter(el => el.includes('etap_'))
            .map((val, idx) => {
                download[idx].pathname = val
                grey[idx].classList.remove('disabled');
            })
    }, 'json').done(function () {
        // alert("success");
    })
    .fail(function () {
        //alert("error");
    })