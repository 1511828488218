$(document).ready(function () {
    $(window).scroll(() => {
        if ($(window).scrollTop() > $('.busstop').offset().top - $('.busstop').outerHeight() && $(window).scrollTop() < $('.busstop').offset().top +  $('.busstop').outerHeight()) {
            // add class 
            $('.busstop__animation').addClass('busstop__animation--dawid');
        }
        else {
            $('.busstop__animation').removeClass('busstop__animation--dawid');
        }
    })
});