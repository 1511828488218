/** @module google analytics events */

/* $(document).ready(function() {
    $('.hamburger').click(function(){
        $('.nav-menu').toggleClass('visible');
    });
}); */
$(document).ready(function() {
    $('.menu-toggle').click(function() {

        $(this).toggleClass('open');

        //$('opening').slideToggle();

        $('ul').toggleClass('opening');
    });
});
  